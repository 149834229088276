import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';

const LoginBackground = () => {
  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const materialRef = useRef(null);
  const geometryRef = useRef(null);

  useEffect(() => {
    // Set mounted state
    setIsMounted(true);
    
    // Skip if mount ref is not available
    if (!mountRef.current) return;
    
    // Detect if device is low-powered
    const isMobile = window.innerWidth < 768;
    const isLowPowered = isMobile && (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      (navigator.hardwareConcurrency && navigator.hardwareConcurrency <= 4)
    );
    
    // Scene setup
    const scene = new THREE.Scene();
    sceneRef.current = scene;
    scene.background = new THREE.Color(0x000000); // Black background
    
    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      75, 
      window.innerWidth / window.innerHeight, 
      0.1, 
      1000
    );
    camera.position.z = 5;
    
    // Renderer setup - use try/catch to handle potential WebGL issues
    let renderer;
    try {
      renderer = new THREE.WebGLRenderer({ 
        antialias: false, 
        alpha: true,
        powerPreference: isLowPowered ? 'low-power' : 'default'
      });
      rendererRef.current = renderer;
      renderer.setSize(window.innerWidth, window.innerHeight);
      
      // Adjust pixel ratio based on device
      renderer.setPixelRatio(isLowPowered ? 1 : (isMobile ? Math.min(window.devicePixelRatio, 1.5) : window.devicePixelRatio));
    } catch (error) {
      console.error("WebGL renderer creation failed:", error);
      return; // Exit early if renderer creation fails
    }
    
    // Add renderer to DOM - with safety check
    if (mountRef.current && renderer.domElement) {
      try {
        mountRef.current.appendChild(renderer.domElement);
      } catch (error) {
        console.error("Failed to append renderer to DOM:", error);
        return; // Exit early if DOM manipulation fails
      }
    }
    
    // Create particles for the mist effect - reduce count on mobile
    const particleCount = isLowPowered ? 300 : (isMobile ? 500 : 1000);
    const particles = new THREE.BufferGeometry();
    geometryRef.current = particles;
    
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);
    
    // Create particles with random positions and red color variations
    for (let i = 0; i < particleCount * 3; i += 3) {
      // Position
      positions[i] = (Math.random() - 0.5) * 20;
      positions[i + 1] = (Math.random() - 0.5) * 20;
      positions[i + 2] = (Math.random() - 0.5) * 20;
      
      // Color - variations of red
      colors[i] = 0.7 + Math.random() * 0.3; // Red (0.7-1.0)
      colors[i + 1] = 0.0 + Math.random() * 0.2; // Green (0.0-0.2)
      colors[i + 2] = 0.0 + Math.random() * 0.2; // Blue (0.0-0.2)
    }
    
    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particles.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    
    // Material for particles - adjust size for mobile
    const particleMaterial = new THREE.PointsMaterial({
      size: isLowPowered ? 0.2 : (isMobile ? 0.15 : 0.1),
      vertexColors: true,
      transparent: true,
      opacity: 0.8,
      blending: THREE.AdditiveBlending
    });
    materialRef.current = particleMaterial;
    
    // Create the particle system
    const particleSystem = new THREE.Points(particles, particleMaterial);
    scene.add(particleSystem);
    
    // Add a subtle red ambient light
    const ambientLight = new THREE.AmbientLight(0x330000);
    scene.add(ambientLight);
    
    // Add a red point light for dramatic effect
    const pointLight = new THREE.PointLight(0xff0000, 1, 100);
    pointLight.position.set(0, 0, 5);
    scene.add(pointLight);
    
    // Animation variables
    let frame = 0;
    let lastUpdateTime = 0;
    
    // Animation loop - adjust animation speed and frame rate for different devices
    const animationSpeed = isLowPowered ? 0.002 : (isMobile ? 0.003 : 0.005);
    const updateInterval = isLowPowered ? 100 : (isMobile ? 50 : 0); // ms between updates for low-power devices
    
    const animate = (timestamp) => {
      // Safety check - don't continue animation if component is unmounting
      if (!mountRef.current || !isMounted || !rendererRef.current) {
        return;
      }
      
      animationFrameIdRef.current = requestAnimationFrame(animate);
      
      // For low-power devices, only update animation at specified intervals
      if (updateInterval > 0 && timestamp - lastUpdateTime < updateInterval) {
        return;
      }
      
      lastUpdateTime = timestamp;
      frame += animationSpeed;
      
      // Rotate the particle system
      particleSystem.rotation.y = frame * 0.1;
      particleSystem.rotation.x = frame * 0.05;
      
      // Move particles to create flowing mist effect
      // Only update every other frame on mobile for performance
      if (!isMobile || Math.floor(frame * 100) % 2 === 0) {
        const positions = particles.attributes.position.array;
        
        // For low-power devices, update fewer particles per frame
        const updateStep = isLowPowered ? 6 : 3;
        
        for (let i = 0; i < particleCount * 3; i += updateStep) {
          // Apply sine wave movement to create flowing effect
          positions[i + 1] += Math.sin(frame + positions[i] * 0.1) * 0.01;
          positions[i] += Math.cos(frame + positions[i + 1] * 0.1) * 0.01;
          
          // Reset particles that move too far
          if (Math.abs(positions[i]) > 10) positions[i] = (Math.random() - 0.5) * 20;
          if (Math.abs(positions[i + 1]) > 10) positions[i + 1] = (Math.random() - 0.5) * 20;
        }
        
        particles.attributes.position.needsUpdate = true;
      }
      
      // Pulse the point light
      pointLight.intensity = 1 + Math.sin(frame) * 0.5;
      
      // Final safety check before rendering
      if (rendererRef.current && sceneRef.current) {
        rendererRef.current.render(scene, camera);
      }
    };
    
    // Handle window resize
    const handleResize = () => {
      if (!mountRef.current || !rendererRef.current) return;
      
      const newIsMobile = window.innerWidth < 768;
      
      // Update camera
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      
      // Update renderer
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
      rendererRef.current.setPixelRatio(newIsMobile ? Math.min(window.devicePixelRatio, 1.5) : window.devicePixelRatio);
    };
    
    window.addEventListener('resize', handleResize);
    
    // Start animation
    animate(0);
    
    // Cleanup function - this runs when component unmounts
    return () => {
      // Set mounted state to false first to stop animations
      setIsMounted(false);
      
      // Remove event listeners
      window.removeEventListener('resize', handleResize);
      
      // Cancel any pending animation frames
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
      
      // Safely remove renderer from DOM
      const safelyRemoveRenderer = () => {
        if (mountRef.current && rendererRef.current && rendererRef.current.domElement) {
          // Check if the element is actually a child before removing
          const isChild = Array.from(mountRef.current.children).includes(rendererRef.current.domElement);
          
          if (isChild) {
            try {
              mountRef.current.removeChild(rendererRef.current.domElement);
            } catch (e) {
              console.log('Error removing renderer:', e);
            }
          }
        }
      };
      
      // Execute with a small delay to ensure React has finished its updates
      setTimeout(safelyRemoveRenderer, 0);
      
      // Dispose of THREE.js resources
      if (materialRef.current) {
        materialRef.current.dispose();
        materialRef.current = null;
      }
      
      if (geometryRef.current) {
        geometryRef.current.dispose();
        geometryRef.current = null;
      }
      
      if (rendererRef.current) {
        rendererRef.current.dispose();
        rendererRef.current = null;
      }
      
      if (sceneRef.current) {
        sceneRef.current.clear();
        sceneRef.current = null;
      }
    };
  }, []);
  
  return (
    <div 
      ref={mountRef} 
      style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%',
        zIndex: 0,
        overflow: 'hidden'
      }}
    />
  );
};

export default LoginBackground; 