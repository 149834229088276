import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import CommonerDashboard from './CommonerDashboard';
import AdventurerDashboard from './AdventurerDashboard';
import AdminDashboard from './AdminDashboard';
import './Dashboard.css';

const Dashboard = () => {
  const { currentUser, loading, logout } = useAuth();
  const [messages, setMessages] = useState([]);
  const [lastSession, setLastSession] = useState('');
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  useEffect(() => {
    // Fetch messages and last session info from server
    // This will be implemented later
    const fetchDashboardData = async () => {
      if (currentUser) {
        // Mock data for now
        setMessages([
          { id: 1, from: 'Game Master', content: 'Welcome to First Veil!', date: new Date().toISOString() }
        ]);
        setLastSession('The party ventured into the Crimson Mist and discovered ancient ruins...');
      }
    };

    fetchDashboardData();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (loading) {
    return (
      <div className="dashboard-loading">
        <div className="loading-spinner"></div>
        <p>Loading your realm data...</p>
      </div>
    );
  }

  if (!currentUser) {
    return null; // User not authenticated, should be redirected by AuthContext
  }

  // Render different dashboard based on user role
  const renderDashboard = () => {
    switch (currentUser.role) {
      case 'admin':
        return <AdminDashboard />;
      case 'adventurer':
        return (
          <AdventurerDashboard 
            messages={messages} 
            lastSession={lastSession}
          />
        );
      case 'commoner':
      default:
        return <CommonerDashboard />;
    }
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>First Veil</h1>
        <div className="user-info">
          <div className="user-avatar">
            {currentUser.avatar ? (
              <img src={currentUser.avatar} alt={currentUser.displayName} />
            ) : (
              <div className="avatar-placeholder">
                {currentUser.displayName.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
          <div className="user-details">
            <div className="user-name">{currentUser.displayName}</div>
            <div className="user-role">
              <span className={`role-badge role-${currentUser.role}`}>
                {currentUser.role.charAt(0).toUpperCase() + currentUser.role.slice(1)}
              </span>
            </div>
          </div>
          <button 
            className="logout-button" 
            onClick={() => setShowLogoutConfirm(true)}
            aria-label="Logout"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
          </button>
        </div>
      </header>

      <main className="dashboard-content">
        {renderDashboard()}
      </main>

      {showLogoutConfirm && (
        <div className="logout-confirm-modal">
          <div className="logout-confirm-content">
            <h3>Confirm Logout</h3>
            <p>Are you sure you want to leave the realm?</p>
            <div className="logout-confirm-buttons">
              <button 
                className="confirm-button"
                onClick={handleLogout}
              >
                Leave Realm
              </button>
              <button 
                className="cancel-button"
                onClick={() => setShowLogoutConfirm(false)}
              >
                Stay
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard; 