import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoginPage from './login-page/LoginPage';
import Dashboard from './dashboard/Dashboard';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';

// Main App component
function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

// App content that depends on authentication state
function AppContent() {
  const { currentUser, loading } = useAuth();
  const [showDashboard, setShowDashboard] = useState(false);
  const [pageTransition, setPageTransition] = useState('');
  
  // Effect to handle transition from login to dashboard
  useEffect(() => {
    if (currentUser) {
      // Set transition class first
      setPageTransition('fade-out');
      
      // Small delay to allow for smooth transition
      const timer = setTimeout(() => {
        setShowDashboard(true);
        // Reset transition class after component change
        setTimeout(() => setPageTransition('fade-in'), 50);
      }, 300);
      
      return () => clearTimeout(timer);
    } else {
      if (showDashboard) {
        // Transition out of dashboard
        setPageTransition('fade-out');
        const timer = setTimeout(() => {
          setShowDashboard(false);
          // Reset transition class after component change
          setTimeout(() => setPageTransition('fade-in'), 50);
        }, 300);
        return () => clearTimeout(timer);
      } else {
        setPageTransition('fade-in');
      }
    }
  }, [currentUser, showDashboard]);
  
  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
        <p>Entering the realm...</p>
      </div>
    );
  }
  
  return (
    <div className={`App ${pageTransition}`}>
      <div className="content">
        {!currentUser && <LoginPage />}
        {showDashboard && currentUser && <Dashboard />}
      </div>
    </div>
  );
}

export default App;
