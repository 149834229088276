import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ messages, lastSession }) => {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [chatError, setChatError] = useState('');
  const chatEndRef = useRef(null);
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Mock gallery images - will be replaced with actual gallery API
  const galleryImages = [
    { id: 1, url: 'https://via.placeholder.com/300x200/8B0000/FFFFFF?text=Campaign+Image+1', title: 'Battle at Crimson Keep' },
    { id: 2, url: 'https://via.placeholder.com/300x200/000000/FFFFFF?text=Campaign+Image+2', title: 'The Ancient Ruins' },
    { id: 3, url: 'https://via.placeholder.com/300x200/4A0000/FFFFFF?text=Campaign+Image+3', title: 'Meeting the Oracle' },
  ];
  
  // Fetch chat messages from API
  const fetchChatMessages = async () => {
    try {
      console.log('Fetching chat messages from:', `${API_URL}/chat`);
      const response = await fetch(`${API_URL}/chat`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch chat messages');
      }
      
      const data = await response.json();
      setChatMessages(data);
      
      // Count unread messages
      const unread = data.filter(message => !message.read).length;
      setUnreadMessages(unread);
    } catch (err) {
      console.error('Error fetching chat messages:', err);
      setChatError('Failed to load chat messages. Please try again later.');
    }
  };
  
  // Initial data fetch
  useEffect(() => {
    fetchChatMessages();
    
    // Set up polling for new messages every 10 seconds
    const intervalId = setInterval(() => {
      if (activeTab === 'chat') {
        fetchChatMessages();
      }
    }, 10000);
    
    return () => clearInterval(intervalId);
  }, [activeTab]);
  
  // Scroll to bottom of chat when new messages arrive
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);
  
  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!newMessage.trim()) return;
    
    setIsSendingMessage(true);
    setChatError('');
    
    try {
      console.log('Sending chat message to:', `${API_URL}/chat`);
      const response = await fetch(`${API_URL}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          content: newMessage
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to send message');
      }
      
      // Get the newly created message from the response
      const newChatMessage = await response.json();
      
      // Add to local state for immediate display
      setChatMessages(prevMessages => [...prevMessages, newChatMessage]);
      setNewMessage('');
      
      // Refresh messages to ensure we have the latest
      fetchChatMessages();
    } catch (err) {
      console.error('Error sending chat message:', err);
      setChatError(err.message || 'Failed to send message. Please try again.');
    } finally {
      setIsSendingMessage(false);
    }
  };
  
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('Image too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      // Convert file to base64 for demo purposes
      // In production, you would upload to a server
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Image = reader.result;
        
        // Update user profile with new avatar
        try {
          console.log('Updating avatar at:', `${API_URL}/profile`);
          const response = await fetch(`${API_URL}/profile`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({
              avatar: base64Image
            })
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to update avatar');
          }
          
          setAvatarUrl(base64Image);
          setUploadError('');
        } catch (err) {
          setUploadError(err.message || 'Failed to update avatar');
          console.error('Error updating avatar:', err);
        }
      };
      
      reader.onerror = () => {
        setUploadError('Error reading file');
      };
    } catch (err) {
      setUploadError(err.message || 'An error occurred during upload');
      console.error('Error uploading avatar:', err);
    } finally {
      setIsUploading(false);
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    
    // If switching to chat tab, clear unread messages
    if (tab === 'chat') {
      setUnreadMessages(0);
    }
  };
  
  return (
    <div className="adventurer-dashboard">
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'gallery' ? 'active' : ''}`}
          onClick={() => handleTabClick('gallery')}
        >
          Gallery
        </button>
        <button 
          className={`tab-button ${activeTab === 'chat' ? 'active' : ''}`}
          onClick={() => handleTabClick('chat')}
        >
          Party Chat {unreadMessages > 0 && <span className="message-badge">{unreadMessages}</span>}
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('profile')}
        >
          Profile
        </button>
        <button 
          className={`tab-button ${activeTab === 'links' ? 'active' : ''}`}
          onClick={() => handleTabClick('links')}
        >
          Important Links
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <div className="gm-messages">
              <h3>Messages from the Game Master</h3>
              {messages.length > 0 ? (
                <div className="message-list">
                  {messages.map(message => (
                    <div key={message.id} className="gm-message">
                      <div className="message-header">
                        <span className="message-from">{message.from}</span>
                        <span className="message-date">{formatDate(message.date)}</span>
                      </div>
                      <div className="message-content">{message.content}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-messages">No messages from the Game Master yet.</p>
              )}
            </div>
            
            <div className="last-session">
              <h3>Last Session Recap</h3>
              {lastSession ? (
                <div className="session-recap">{lastSession}</div>
              ) : (
                <p className="no-recap">No session recap available.</p>
              )}
            </div>
            
            <div className="quick-links">
              <h3>Quick Links</h3>
              <div className="links-grid">
                <a href="https://firstveil.com" target="_blank" rel="noopener noreferrer" className="quick-link website">
                  <span className="link-icon">🌐</span>
                  <span className="link-text">First Veil Website</span>
                </a>
                <a href="http://47.7.21.116:30000/join" target="_blank" rel="noopener noreferrer" className="quick-link foundry">
                  <span className="link-icon">🎲</span>
                  <span className="link-text">FoundryVTT</span>
                </a>
                <a href="https://discord.gg/SGMCCwsd" target="_blank" rel="noopener noreferrer" className="quick-link discord">
                  <span className="link-icon">💬</span>
                  <span className="link-text">Discord Channel</span>
                </a>
                <button onClick={() => handleTabClick('gallery')} className="quick-link gallery">
                  <span className="link-icon">🖼️</span>
                  <span className="link-text">Campaign Gallery</span>
                </button>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'gallery' && (
          <div className="gallery-tab">
            <h3>Campaign Gallery</h3>
            <div className="gallery-grid">
              {galleryImages.map(image => (
                <div key={image.id} className="gallery-item">
                  <img src={image.url} alt={image.title} />
                  <div className="image-title">{image.title}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {activeTab === 'chat' && (
          <div className="chat-tab">
            <h3>Party Chat</h3>
            <div className="chat-container">
              <div className="chat-messages">
                {chatMessages.length > 0 ? (
                  chatMessages.map(msg => (
                    <div 
                      key={msg.id} 
                      className={`chat-message ${msg.sender === currentUser.displayName ? 'own-message' : ''}`}
                    >
                      <div className="message-sender">{msg.sender}</div>
                      <div className="message-bubble">{msg.content}</div>
                      <div className="message-time">{formatDate(msg.timestamp)}</div>
                    </div>
                  ))
                ) : (
                  <div className="no-messages">No messages yet. Be the first to say hello!</div>
                )}
                {chatError && <div className="chat-error">{chatError}</div>}
                <div ref={chatEndRef} />
              </div>
              
              <form className="chat-input" onSubmit={handleSendMessage}>
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type your message..."
                  disabled={isSendingMessage}
                />
                <button 
                  type="submit" 
                  disabled={!newMessage.trim() || isSendingMessage}
                >
                  {isSendingMessage ? 'Sending...' : 'Send'}
                </button>
              </form>
            </div>
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <h3>Your Adventurer Profile</h3>
            
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt={currentUser.displayName} />
                ) : (
                  <div className="avatar-placeholder">
                    {currentUser.displayName.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Change Avatar</h4>
                <p>Upload a small image (max 2MB) to represent your character</p>
                
                <label className="upload-button">
                  {isUploading ? 'Uploading...' : 'Choose Image'}
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <span className="info-label">Display Name:</span>
                <span className="info-value">{currentUser.displayName}</span>
              </div>
              <div className="info-item">
                <span className="info-label">Email:</span>
                <span className="info-value">{currentUser.email}</span>
              </div>
              <div className="info-item">
                <span className="info-label">Role:</span>
                <span className="info-value role-adventurer">Adventurer</span>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'links' && (
          <div className="links-tab">
            <h3>Important Links</h3>
            
            <div className="links-container">
              <div className="link-card">
                <h4>FoundryVTT</h4>
                <p>Access the virtual tabletop for your campaigns</p>
                <a 
                  href="http://47.7.21.116:30000/join" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="link-button"
                >
                  Open FoundryVTT
                </a>
              </div>
              
              <div className="link-card">
                <h4>Discord</h4>
                <p>Join our community Discord server</p>
                <a 
                  href="https://discord.gg/SGMCCwsd" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="link-button"
                >
                  Join Discord
                </a>
              </div>
              
              <div className="link-card">
                <h4>Character Sheet</h4>
                <p>Access your digital character sheet</p>
                <a 
                  href="#" 
                  onClick={() => alert('Character sheet feature coming soon!')}
                  className="link-button"
                >
                  View Character Sheet
                </a>
              </div>
              
              <div className="link-card">
                <h4>Campaign Wiki</h4>
                <p>Browse the campaign lore and world information</p>
                <a 
                  href="#" 
                  onClick={() => alert('Campaign wiki feature coming soon!')}
                  className="link-button"
                >
                  Open Wiki
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 