import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import './CommonerDashboard.css';

const CommonerDashboard = () => {
  const { currentUser } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  const handleRequestAccess = async () => {
    setLoading(true);
    
    try {
      const response = await fetch(`${API_URL}/request-role-upgrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          userId: currentUser.id,
          requestedRole: 'adventurer'
        })
      });
      
      if (response.ok) {
        setRequestSent(true);
      }
    } catch (err) {
      console.error('Error sending request:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="commoner-dashboard">
      <div className="access-request-container">
        {requestSent ? (
          <p className="request-status">Request sent</p>
        ) : (
          <button 
            className="request-access-button"
            onClick={handleRequestAccess}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Request Access'}
          </button>
        )}
      </div>
    </div>
  );
};

export default CommonerDashboard; 